var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0988448049bee17810f39326bb98d0cf8098fbfc"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://f2bc25a20bbfdc09852e9bc0c9037a44@o1291986.ingest.us.sentry.io/4508442548568064',
  tracesSampleRate: 0.2,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
});
