import styles from '@/shared/styles/MainPage.module.css';
import Image from 'next/image';
import { Roboto } from 'next/font/google';
import cn from 'classnames';

import QRCodeImage from '@/../public/qrcode.png';

const roboto = Roboto({
  subsets: ['cyrillic', 'latin'],
  weight: ['700', '400'],
});

interface Props {}

const MainPage: React.FC<Props> = () => {
  return (
    <div className={cn(styles.container, roboto.className)}>
      <h1>По вопросам сотрудничества</h1>
      <Image src={QRCodeImage} width={250} alt="Telegram QR-code" />
      <a href="https://t.me/paykong" target="_blank" rel="noreferrer" className={styles.telegramLink}>
        Открыть в Telegram
      </a>
    </div>
  );
};

export default MainPage;
